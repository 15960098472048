body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

.ant-typography,
.ant-btn, 
.ant-input {
  font-family: "Inter", sans-serif;
}

code {
  font-family: "Inter", sans-serif;
}

.fs-16 {
  font-size: 16px;
}

.fs-title{
  font-size: 24px;
}

.fs-content {
  font-size: 16px;
}

@media screen and (max-width:768px) {
  .fs-title{
    font-size: 16px;
  }
  .fs-content {
    font-size: 12px;
  }
}
.bold {
  font-weight: bold;
}
.ant-menu-horizontal {
  border-bottom: none;
}

.fs-14 {
  font-size: 14px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-32 {
  margin-left: 32px;
}
.my-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.m-0 {
  margin: 0;
}

.w-full {
  width: 100% !important;
}

.h-full {
  height: 100%;
}

.ant-layout-has-sider {
  height: 100%;
  min-height: 100vh;
}

.text-overflow {
  display: block;
  max-width: 98%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.p-content {
  padding: 0px 16px 16px 16px;
}

.ant-menu-vertical {
  border-inline-end: 0px !important;
}

.ant-menu-inline {
  border-inline-end: 0px !important;
}


.border {
  border: 1px solid red;
}

.mt-10 {
  margin-top: 10px;
}

.cursor {
  cursor: pointer;
}

.strong {
  font-weight: bold;
}

.ant-drawer .ant-drawer-header {
  border-bottom: none !important;
}

.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: #2B5363;
  transform-origin: 0%;
}

.ant-btn-primary {
  box-shadow: none;
}

.ant-float-btn {
  inset-block-end: 60px;
}

.ant-menu-item-selected::after,
.ant-menu-item::after {
  border: none !important;
}

.content {
  width: 100%;
  padding: 0px 12px;
}

.logo-img {
  max-width: 200px;
  max-height: 200px;
}


.s-enter-to,
.go526437840,
.go2628359365 {
  background: white !important;
}

.go3205121330 {
  color: black !important;
}

@media screen and (min-width:992px) {
  .ant-float-btn {
    inset-block-end: 39px;
  }

  .content {
    width: 100%;
    padding: 0rem;
  }

}

@media screen and (max-width: 768px) {
  .content {
    width: 100%;
    padding: 0;
  }

}

@media screen and (max-width:600px) {
  .go1140934594 {
    font-size: 12px !important;
  }

  .go157313249 {
    min-width: 100px !important;
    padding: 12px 8px !important;
  }
}

@media screen and (max-width: 426px) {
  .logo-img {
    max-width: 120px;
    max-height: 120px;
  }

  .button-menu {
    max-width: 120px;
    max-height: 120px;
  }

}

::-webkit-scrollbar {
  display: none;
}